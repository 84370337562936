import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import title from "../images/title/artist.png"
import titleMobile from "../images/title/artist-mobile.png"
import titleDecoMobile from "../images/deco/deco-img-06-mobile.png"
import titleDeco from "../images/deco/deco-img-06.png"
import bgDecoMobile from "../images/deco/deco-img-05-mobile.png"
import bgDeco from "../images/deco/deco-img-05.png"
import bgDeco07 from "../images/deco/deco-img-07-mobile.png"
import bgDeco01 from "../images/deco/deco-img-01.png"
import wave from "../images/waves/wave04.png"
import waveMobile from "../images/waves/wave04-mobile.png"


const Wrap = styled.div`
  position: relative;
  background-image: url(${bgDeco01}), url(${bgDeco07}), url(${waveMobile}), linear-gradient(to bottom, #3FCB51 0%, #F4BC9F 14%);
  background-repeat: no-repeat;
  background-position: top left, bottom 74px right 30px, bottom center, top left;
  background-size: 0, 93px, 100%, auto;
  padding: 57px 0 107px;
  margin-top: -1px;
  @media (min-width: 768px) {
    background-image: url(${bgDeco01}), url(${bgDeco07}), url(${wave}), linear-gradient(to bottom, #9EC37A 0%, #F4BC9F 25%);
    background-position: top 0px right 14%,bottom 29% right 12%,bottom center,top left;
    background-size: 165px, 182px, 100%, auto;
    padding: 0 0 269px;
  }
`

const TitleImg = styled.img`
  width: 110px;
  @media (min-width: 768px) {
    width: 237px;
  }
`

const TitleDecoImg = styled.img`
  width: 145px;
  margin-left: -18px;
  @media (min-width: 768px) {
    width: 252px;
    display: block;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  position: relative;
  position: relative;
  @media (min-width: 768px) {
    width: 100%;
    flex-direction: row;
    max-width: 914px;
    gap: 42px;
    padding: 0;
    margin: 0 auto;
  }
`

const ContentText = styled.div`
  padding: 17px 61px;
  font-family: 'Average Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FF7A00;
  text-align: center;
  margin-top: -8px;
  @media (min-width: 768px) {
    margin-top: 0;
    padding: 0;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }
`

const BgDecoImg = styled.img`
  position: absolute;
  top: 0;
  left: -22px;
  width: 137px;
  transform: translateY(-50%);
  @media (min-width: 768px) {
    left: -28px;
    top: 279px;
    width: 330px;
    transform: none;
  }
`

const AtristSection = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  return <Wrap>
    <Content>
      <div>
        <TitleImg src={isDesktop ? title : titleMobile} alt="title" />
        {!isDesktop && <TitleDecoImg src={titleDecoMobile} alt="deco" />}
      </div>
      <ContentText>
        {isDesktop && <TitleDecoImg src={titleDeco} alt="deco" />}
        DG is a legendary painter having his experience in different kinds of fields. The background of DG's paintings has strong vitality, and it can be closely related to people and life in the paintings. One of his works sold at a high price of 360,000 US dollars in 2021 and the other series of his works have been collected by Dotard Village International Gallery. Now his paintings have been exhibited in several famous galleries around the world.
      </ContentText>
    </Content>
    <BgDecoImg src={isDesktop ? bgDeco : bgDecoMobile} alt="deco" />
  </Wrap>
}

export default AtristSection