import { Modal } from 'antd';
import "antd/dist/antd.css";
import {
  BigNumber, ethers,
  // ethers
} from 'ethers';

import { useEffect, useState } from 'react';
import {
  MAX_SUPPLY,
  // MINT_PRICE,
  // START_TIME
} from './constants';
import { openNotificationWithIcon } from './helpers/notification';
import {
  // useTemplateNFTContract,
  useDeedOfGenesisContract
} from './hooks/useContract';
import { useActiveWeb3React } from './hooks/web3';
import "./style.css";
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive'
import WHITELIST from './whitelist/whitelist.json';
import AppHeader from './components/AppHeader';
import Banner from './components/Banner';
import MintSection from './components/MintSection';
import StorySection from './components/StorySection';
import AtristSection from './components/AtristSection';
import AppFooter from './components/AppFooter';
import FaqSection from './components/FaqSection';
import TeamSection from './components/TeamSection';
import modalBg from "./images/bg/bg-02.png"
import modalBgMobile from "./images/bg/bg-02-mobile.png"
import mintModalImg from './images/mint-modal-img.png'

const ModalClose = styled.div`
  position: absolute;
  top: 13px;
  right: 8px;
  background: #fff;
  border-radius: 50%;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    cursor: pointer;
  }
  @media (min-width: 768px) {
    width: 34px;
    height: 34px;
    font-size: 20px;
    top: 30px;
    right: 40px;
  }
`

const ModalInfoBlock = styled.div`
  background: #fff;
  padding: 12px 26px 28px;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  @media (min-width: 768px) {
    padding: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const MintCardBlock = styled.div`
  background: #fff;
  padding: 12px 26px 28px;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 100%;
  @media (min-width: 768px) {
    padding: 24px 43px 35px;
    width: 347px;
    margin-bottom: 0;
    flex-shrink: 0;
  }
`

const ModalInfoTitle = styled.div`
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #FF7043;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    font-size: 18px;
    margin-bottom: 16px;
  }
`

const ModalInfoPrice = styled.div`
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 21px;
  }
`

const ModalInfoContent = styled.div`
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  @media (min-width: 768px) {
    font-size: 10px;
  }
`

const ModalInfoSubtitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #B64E4E;
  margin-bottom: 18px;
  @media (min-width: 768px) {
    margin-bottom: 20px;
    font-size: 12px;
  }
`

const ModalInfoImg = styled.img`
  width: 133px;
  margin-bottom: 17px;
  @media (min-width: 768px) {
    width: 211px;
    margin-bottom: 8px;
  }
`

const PriceSection = styled.div`
  padding: 20px 0;
  border-top: 3px solid #FF7777;
  border-bottom: 3px solid #FF7777;
  @media (min-width: 768px) {
    padding: 13px 0;
    border-top: 2px solid #FF7777;
    border-bottom: 2px solid #FF7777;
  }
`

const MintActionWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 0;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 23px;
  }
`

const AddBtn = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  position: relative;
  background: #FFC2C2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &::before {
    content: '';
    width: 16px;
    height: 2px;
    background: #B64E4E;
  }
  &::after {
    content: '';
    width: 2px;
    height: 16px;
    background: #B64E4E;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &:hover {
    cursor: pointer;
    background: #B64E4E;
  }
  &:hover::before,
  &:hover::after {
    background: #FFC2C2;
  }
  &:disabled {
    cursor: not-allowed;
    background: #838383;
  }
  &:disabled::before,
  &:disabled::after {
    background: #fff;
  }
`

const MinusBtn = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #FFC2C2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:before {
    content: '';
    width: 16px;
    height: 2px;
    background: #B64E4E;
  }
  &:hover {
    cursor: pointer;
    background: #B64E4E;
  }
  &:hover::before {
    background: #FFC2C2;
  }
  &:disabled {
    cursor: not-allowed;
    background: #838383;
  }
  &:disabled::before {
    background: #fff;
  }
`

const MintCount = styled.div`
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin: 0 35px;
  @media (min-width: 768px) {
    margin: 0 20px;
    font-size: 25px;
  }
`

const MintButton = styled.button`
  width: 100px;
  border-radius: 8px;
  text-align: center;
  height: 47px;
  line-height: 47px;
  border: none;
  background: #FFC2C2;
  box-shadow: 0px 2px 2px rgba(122, 93, 93, 0.71);
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #B64E4E;
  letter-spacing: 0.14em;
  margin-top: 25px;
  &:hover {
    cursor: pointer;
    background: #B64E4E;
    color: #FFC2C2;
  }
  &:disabled {
    cursor: not-allowed;
    background: #838383;
    color: #fff;
  }
  @media (min-width: 768px) {
    width: 100px;
    border-radius: 15px;
    margin-top: 0;
    font-size: 25px;
  }
`

type Voucher = {
  voucher: {
    redeemer: string;
    stageId: number;
    nonce: number;
    amount: number;
  };
  signature: string;
}

type StageInfo = {
  isPublic: boolean;
  stageId: number;
  maxSupply: number;
  startTime: number;
  endTime: number;
  mintPrice: BigNumber;
}
const whitelist: Map<string, Voucher> = new Map(Object.entries(WHITELIST));
const fromEther = (bn: BigNumber | undefined) => {
  if (bn) {
    return ethers.utils.formatEther(bn?.toString())
  }
}

function App() {
  const [mintButtonDisabled, setMintButtonDisabled] = useState(false)
  const [amount, setMintAmount] = useState('1');
  const [stageInfo, setStageInfo] = useState<StageInfo>()
  const { account, chainId } = useActiveWeb3React();
  const [totalSupply, setTotalSupply] = useState(0);
  const dogContract = useDeedOfGenesisContract();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  useEffect(() => {
    if (!chainId) return;
    if (chainId !== 1 && chainId !== 1337 && chainId !== 4) {
      openNotificationWithIcon('info', 'Please connect to Ethereum Mainnet', 'Switch your network to Ethereum Mainnet')
      return;
    }
    const getStageInfto = async () => {
      if (!dogContract) return;
      setStageInfo((await dogContract?.stageInfo()))
      setTotalSupply((await dogContract.totalSupply()).toNumber())

    }
    getStageInfto()
  }, [chainId, dogContract])

  const addMintAmout = () => {
    // pre sale 最多 2 個, public sale 最多 3 個
    setMintAmount((Number(amount) + 1).toString())
  }

  const minusMintAmout = () => {
    if (Number(amount) <= 1) return
    setMintAmount((Number(amount) - 1).toString())
  }

  const handleClick = async () => {
    if (!account) {
      openNotificationWithIcon('info', 'Please connect to Wallet', "")
      return;
    }
    if (chainId !== 1 && chainId !== 1337 && chainId !== 4) {
      openNotificationWithIcon('info', 'Please connect to Ethereum Mainnet', 'Switch your network to Ethereum Mainnet')
      return;
    }
    if (!amount) return;

    if (stageInfo?.isPublic === false) {

      const data = whitelist.get(account)
      if (!data) {
        openNotificationWithIcon('info', 'Not in whitelist', '')
        return
      }
      if (stageInfo.startTime * 1000 > Date.now()) {
        openNotificationWithIcon('info', "Sales has'nt started yet", '')
        return
      }
      const voucher = data?.voucher
      const signature = data?.signature

      try {
        setMintButtonDisabled(true)
        const tx = await dogContract?.whitelistMint(voucher, signature, amount, {
          value: stageInfo?.mintPrice?.mul(amount)
        })
        const receipt = await tx?.wait()
        if (receipt?.status) {
          openNotificationWithIcon('success', 'Mint success!', "Welcome to the templateNFT! You can now check on Opensea.")
          setMintAmount('1')
        }
      } catch (err) {
        setMintButtonDisabled(false)
        // @ts-ignore:next-line
        if (err.code === "INSUFFICIENT_FUNDS") {
          openNotificationWithIcon('error', 'INSUFFICIENT FUND', "You might not have enough fund to perform this opertaion")
          return
        }
        // @ts-ignore:next-line
        if (err.code === 4001) {
          openNotificationWithIcon('error', 'User denied transaction signature', "")
          return
        }
        openNotificationWithIcon('error', 'Error while sending transaction', "")
      }
    } else {
      try {
        setMintButtonDisabled(true)
        const tx = await dogContract?.publicMint(amount, {
          value: stageInfo?.mintPrice?.mul(amount)
        })
        const receipt = await tx?.wait()
        if (receipt?.status) {
          openNotificationWithIcon('success', 'Mint success!', "Welcome to the templateNFT! You can now check on Opensea.")
          setMintAmount('1')
        }
      } catch (err) {
        setMintButtonDisabled(false)
        // @ts-ignore:next-line
        if (err.code === "INSUFFICIENT_FUNDS") {
          openNotificationWithIcon('error', 'INSUFFICIENT FUND', "You might not have enough fund to perform this opertaion")
          return
        }
        // @ts-ignore:next-line
        if (err.code === 4001) {
          openNotificationWithIcon('error', 'User denied transaction signature', "")
          return
        }
        openNotificationWithIcon('error', 'Error while sending transaction', "")
      }
    }

    setMintButtonDisabled(false)
  }

  return (
    <div>
      <AppHeader />
      <Banner />
      <MintSection mintButtonClick={() => { setIsModalVisible(true) }} />
      <StorySection />
      <AtristSection />
      <TeamSection />
      <FaqSection />
      <AppFooter />
      <Modal visible={isModalVisible} footer={null} closable={false}>
        <div className='custom-body' style={{ backgroundImage: `url(${isDesktop ? modalBg : modalBgMobile})` }}>
          <ModalClose onClick={() => { setIsModalVisible(false) }}>X</ModalClose>
          <div style={{ width: '100%', marginRight: isDesktop ? '64px' : '0' }}>
            <ModalInfoBlock>
              <ModalInfoTitle>Wallet Address</ModalInfoTitle>
              <ModalInfoContent>{account}</ModalInfoContent>
            </ModalInfoBlock>
            <ModalInfoBlock>
              <ModalInfoTitle>Supply</ModalInfoTitle>
              <ModalInfoContent>{MAX_SUPPLY - totalSupply} / {MAX_SUPPLY}</ModalInfoContent>
            </ModalInfoBlock>
            <ModalInfoBlock>
              <ModalInfoTitle>Sale Status</ModalInfoTitle>
              <ModalInfoSubtitle>
                Pre Sale
              </ModalInfoSubtitle>
              <ModalInfoContent style={{ marginBottom: '20px' }}>
                Mint price : 0.05 ETH<br />
                Max 2 pcs / per wallet
              </ModalInfoContent>
              <ModalInfoSubtitle>
                Public Sale
              </ModalInfoSubtitle>
              <ModalInfoContent>
                Mint price : 0.05 ETH<br />
                Max 3 pcs / per wallet
              </ModalInfoContent>
            </ModalInfoBlock>
          </div>
          <MintCardBlock>
            <ModalInfoImg src={mintModalImg} alt="mint" />
            <PriceSection>
              <ModalInfoTitle>Total Price</ModalInfoTitle>
              <ModalInfoPrice>
                {
                  fromEther(stageInfo?.mintPrice.mul(amount)) || 0
                }
              </ModalInfoPrice>
            </PriceSection>
            <MintActionWrap>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MinusBtn disabled={mintButtonDisabled} onClick={minusMintAmout} />
                <MintCount>{amount}</MintCount>
                <AddBtn disabled={mintButtonDisabled} onClick={addMintAmout} />
              </div>
              <MintButton disabled={mintButtonDisabled} onClick={handleClick}>MINT</MintButton>
            </MintActionWrap>
          </MintCardBlock>
        </div>
      </Modal>
    </div >
  );
}

export default App;

// {/* <Content style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//         <div>
//           <div style={{ display: 'flex', justifyContent: 'center' }}>
//             <div style={{ marginBottom: '64px', display: 'flex', flexDirection: 'column', fontFamily: 'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif' }}>
//               <div style={{ display: 'flex', justifyContent: 'center' }}>MINT DATE</div>
//               <div style={{ fontSize: '32px', fontWeight: 'bold', }}>

//                 <Countdown date={stageInfo ? new Date(stageInfo.startTime * 1000).toUTCString() : new Date(START_TIME).toUTCString()} />
//               </div>
//               <div style={{ display: 'flex', justifyContent: 'center', gap: '24px', fontSize: '10px' }}>
//                 <div>Days</div>
//                 <div>Hours</div>
//                 <div>Mins</div>
//                 <div>Secs</div>

//               </div>

//             </div>
//           </div>
//           <Card style={{ borderRadius: '24px', fontFamily: 'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif', display: 'flex', textAlign: 'center', fontSize: '14px', fontWeight: 'bold' }}>
//             <div>
//               {/* <img src={mint} style={{ width: "250px", height: '190px' }} alt='mint-doo-doo' /> */}
//             </div >
//             <div>
//               <Text>{MAX_SUPPLY - totalSupply} / {MAX_SUPPLY} </Text>
//             </div>
//             <div>
//               <Text>left at {stageInfo ? ethers.utils.formatEther(stageInfo.mintPrice.toString()) : MINT_PRICE} ETH each</Text>
//             </div>
//             <div>
//               <Select style={{ marginTop: '16px', width: '210px', borderRadius: '10px' }} defaultValue="1" onChange={handleChange}>
//                 <Option value="1">1</Option>
//                 <Option value="2">2</Option>
//                 <Option value="3">3</Option>
//                 <Option value="4">4</Option>
//                 <Option value="5">5</Option>
//               </Select>
//             </div>
//             <div>
//               <Button style={{ fontWeight: '500', color: '#ffffff', border: '0px solid rgb(174, 99, 63)', background: '#000000', marginTop: '16px', width: '210px', borderRadius: '10px' }} onClick={handleClick} disabled={mintButtonDisabled}>Mint</Button>
//             </div>
//           </Card >
//         </div >
//       </Content > * /}
