import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import title from "../images/title/ourteam.png"
import titleMobile from "../images/title/ourteam-mobile.png"
import MemberCard from './MemberCard'
import { Col, Row } from 'antd';
import Benjamin from '../images/member/Benjamin.png'
import DULU from '../images/member/DULU.png'
import EDWARD from '../images/member/EDWARD.png'
import FUNG from '../images/member/FUNG.png'
import JEFFREY from '../images/member/JEFFREY.png'
import JUSTA from '../images/member/JUSTA.png'
import MARTIN from '../images/member/MARTIN.png'
import MAX from '../images/member/MAX.png'
import Xephy from '../images/member/Xephy.png'
import bg from "../images/bg/bg-01.png"
import bgMobile from "../images/bg/bg-01-mobile.png"
import wave from "../images/waves/wave05.png"
import waveMobile from "../images/waves/wave05-mobile.png"


interface WrapProps {
  bg: string
}
const Wrap = styled.div<WrapProps>`
  text-align: center;  
  padding-bottom: 135px;
  margin-top: -1px;
  background-image: url(${waveMobile}), url(${bgMobile}), linear-gradient(0deg, #FF7777 82.81%, #F4BC9F 100%);
  background-repeat: no-repeat;
  background-position: bottom, top, top;
  background-size: 100%, contain, auto;
  @media (min-width: 768px) {
    background-image: url(${wave}), url(${bg}), linear-gradient(0deg, #FF7777 82.81%, #F4BC9F 100%);
    width: 100%;
    margin: 0 auto;
    padding-bottom: 118px;
  }
`

const TitleImg = styled.img`
  width: 244px;
  margin-bottom: 29px;
  @media (min-width: 768px) {
    width: 389px;
    max-width: 100%;
  }
`

const Content = styled.div`
  padding: 0 36px;
  width: 100%;
  @media (min-width: 768px) {
    padding: 0 0;
    max-width: 766px;
    margin: 0 auto;
  }
`

const Divider = styled.div`
  width: calc(100% - 12px);
  display: inline-block;
  margin: 36px auto;
  height: 2px;
  background: linear-gradient(to right, #FFFFFF, #FFC2C2);
  @media (min-width: 768px) {
    margin: 30px auto;
    height: 3px;
  }
`

const SPECIAL_MEMBER_LIST = [
  { name: 'Benjamin', title: 'Advisor', img: Benjamin, description: ['Blockchain Expert', 'Finance Guy', 'IPO Advisor'], twitterUrl: '' },
  { name: 'Xephy.eth', title: 'Advisor', img: Xephy, description: ['Holder of MAYC#9639, Doodle#6449, CloneX#11342', 'NFT Investor and Collector', 'Art Collector'], twitterUrl: 'https://twitter.com/xephykk' },
]

const MEMBER_LIST = [
  { name: 'Martin Chin', title: 'Founder', img: MARTIN, description: ['NFT Enthusiast', 'Project Lead', 'Strategist'], twitterUrl: 'https://twitter.com/TheMT86' },
  { name: 'DuLu', title: 'Manga Artist', img: DULU, description: ['12 years serialized Manga experience', 'Award-winning Artist', 'Specialties: Science Fiction / Fashion / Alien Species / Thriller / Fairy Tale'], twitterUrl: '' },
  { name: 'Edward', title: 'Community Manager', img: EDWARD, description: ['NFT Enthusiast', 'Marketing Head', 'Content Creator'], twitterUrl: 'https://twitter.com/Edward20000817' },
  { name: 'Max', title: 'Community', img: MAX, description: ['1Crypto enthusiast', 'Marketing specialist', 'Web3 Twitter writer'], twitterUrl: 'https://twitter.com/0xMaxLin' },
  { name: 'Jeffrey', title: 'Web3 Developer', img: JEFFREY, description: ['Founder of Kairos Labs', 'Blockchain developer/auditor', 'DeFi Researcher'], twitterUrl: 'https://twitter.com/billionxdev' },
  { name: 'Justa', title: 'Web3 Developer', img: JUSTA, description: ['Smart Contract Expert', 'NFT and GameFi builder', 'Specialties: Chainlink Oracle, OpenZeppelin, Contracts Wizard'], twitterUrl: '' },
  { name: 'Fung', title: 'Game Player', img: FUNG, description: ['Founder of Nine Fortress Inc.', 'Expert in puzzle game and room escape', 'Web3 believer'], twitterUrl: 'https://twitter.com/frozenfung' },
]

const TeamSection = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  return <Wrap bg={isDesktop ? bg : bgMobile}>
    <TitleImg src={isDesktop ? title : titleMobile} alt="title" />
    <Content>
      <Row gutter={[{ xs: 0, sm: 0, md: 30 }, { xs: 30, sm: 30, md: 30 }]}>
        {SPECIAL_MEMBER_LIST.map((member, index) => (
          <Col key={`special-${index}`} span={24} md={8}>
            <MemberCard {...member} />
          </Col>
        ))}
      </Row>
      <Divider />
      <Row gutter={[{ xs: 0, sm: 0, md: 30 }, { xs: 30, sm: 30, md: 30 }]}>
        {MEMBER_LIST.map((member, index) => (
          <Col key={`normal-${index}`} span={24} md={8}>
            <MemberCard {...member} />
          </Col>
        ))}
      </Row>
    </Content>

  </Wrap>
}

export default TeamSection