import styled from "styled-components"
import { DISCORD_LINK, TWITTER_LINK, OPENSEA_LINK } from '../constants';
import Account from "./Account"
import Discord from "../images/logos/Discord-LOGO.png"
import Opensea from "../images/logos/Opensea-LOGO.png"
import twitter from "../images/logos/twitter-LOGO.png"

const Header = styled.header`
  background: #fff; 
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 13px;
  z-index: 800;
  @media (min-width: 768px) { 
    padding: 0 33px;
    height: 60px;
  }
`

const Logo = styled.img`
  width: 32px;
  @media (min-width: 768px) { 
    width: 36px;
  }
`

const Link = styled.a`
  display: inline-block;
  & + & { 
    margin-left: 18px;
    @media (min-width: 768px) { 
      margin-left: 22px;
    }
  }
`

const AppHeader = () => {
  return <Header>
    <Account />
    <div>
      <Link href={TWITTER_LINK} target="_blank" rel="noreferrer">
        <Logo src={twitter} />
      </Link>
      <Link href={DISCORD_LINK} target="_blank" rel="noreferrer">
        <Logo src={Discord} />
      </Link>
      <Link href={OPENSEA_LINK} target="_blank">
        <Logo src={Opensea} />
      </Link>
    </div>
  </Header>
}

export default AppHeader