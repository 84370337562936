import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import banner from "../images/banner.png"
import hero from "../images/hero.png"
import heroMobile from "../images/hero-mobile.png"
import wave from "../images/waves/wave01.png"
import waveMobile from "../images/waves/wave01-mobile.png"

const Wrap = styled.div`
  position: relative;
  @media (min-width: 768px) {
    min-height: calc(100vh - 60px - 71px - 45px);
    height: calc(100vh - 60px - 71px - 45px);
  }
`

const Img = styled.img`
  width: 100%;
  @media (min-width: 768px) {
    height: 100%;
    object-fit: cover;
    object-position: bottom;
  }
`

const Hero = styled.img`
  width: 115px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 78%);
  z-index: 15;
  @media (min-width: 768px) {
    width: 227px;
    transform: translate(-50%, 46%);
  }
`

const DecoImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(60%);
  z-index: 10;
`

const Banner = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  return <Wrap>
    <Img src={banner} alt="banner" />
    <Hero src={isDesktop ? hero : heroMobile} alt="hero" />
    <DecoImg src={isDesktop ? wave : waveMobile} alt="wave" />
  </Wrap>
}

export default Banner