import { useMediaQuery } from 'react-responsive'
import { useState } from 'react'
import styled from 'styled-components'
import title from "../images/title/faq.png"
import titleMobile from "../images/title/faq-mobile.png"
import { Collapse } from 'react-collapse';

const Wrap = styled.div`
  position: relative;
  background: linear-gradient(180deg, #FF7777 0%, #FDC331 35px);
  overflow: hidden;
  text-align: center;
  padding: 20px 0 40px;
  margin-top: -1px;
  @media (min-width: 768px) {
    background: linear-gradient(180deg,#FF7777 0%,#FDC331 124px);
    padding: 12px 0 40px;
  }
`

const TitleImg = styled.img`
  margin-bottom: 36px;
  width: 170px;
  @media (min-width: 768px) {
    width: 284px;
    margin-bottom: 44px;
  }
`

const Content = styled.div`
  width: 100%;
  padding: 0 23px;
  text-align: left;
  @media (min-width: 768px) {
    max-width: 566px;
    padding: 0;
    margin: 0 auto;
  }
`

const FaqContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  @media (min-width: 768px) {
    margin-bottom: 35px;
  }
`

const SortItem = styled.div`
  background-color: #fff;
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #000;
  margin-right: 20px;
  border-radius: 50%;
  flex-shrink: 0;
  width: 27px;
  height: 27px;
  line-height: 27px;
  text-align: center;
  @media (min-width: 768px) {
    margin-right: 26px;
    font-size: 25px;
    width: 40px;
    height: 40px;
    line-height: 48px;
  }
`

const FaqTitle = styled.div`
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  border-radius: 100px;
  padding: 5px 20px;
  color: #000;
  background-color: #fff;
  width: 100%;
  @media (min-width: 768px) {
    padding: 8px 0 8px 26px;
    border-radius: 60px;
    font-size: 20px;
    width: 500px;
  }
  &:hover {
    cursor: pointer;
  }
`

const FaqContentWrap = styled.div`
  padding-bottom: 39px;
  @media (min-width: 768px) {
    padding-bottom: 54px;
  }
`

const FaqContent = styled.div`
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  border-radius: 100px;
  padding: 11px 20px;
  color: #FF7777;
  border-radius: 20px;
  background-color: #fff;
  margin-left: 47px;
  @media (min-width: 768px) {
    padding: 18px 26px;
    border-radius: 30px;
    font-size: 20px;
    line-height: 25px;
    margin-left: 66px;
  }
`

const FAQ_LIST = [
  { title: 'What are Deed of Genesis NFTs?', content: 'Deed of Genesis is a collection of 5,000 PFPs. A community-driven manga and discord strategy game NFT.' },
  { title: 'How are the Deed of Genesis NFTs distributed?', content: '4500: whitelist + public (Subject to change) 500: Treasury for collaboration and team' },
  { title: 'How were the NFTs created?', content: 'Each NFT has been constructed algorithmically by mixing a variety of traits from and differentiated by different races as the following: God, Angel, Buddha, Human, Giant, Robot, Satan, Fairy, Wizard, Asura, Knight, Ghost.' },
  { title: 'What intellectual property rights do Deed of Genesis NFT holders receive?', content: 'Holders have the commercial usage rights to their Deed of Genesis NFTs. Full details  coming soon.' },
  { title: 'How will the secondary royalties be used?', content: 'Fund will use to expand the team, operation and reward holders.' },
]

const FaqSection = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })
  const [faqOpen_01, setFaqOpen_01] = useState(false)
  const [faqOpen_02, setFaqOpen_02] = useState(false)
  const [faqOpen_03, setFaqOpen_03] = useState(false)
  const [faqOpen_04, setFaqOpen_04] = useState(false)
  const [faqOpen_05, setFaqOpen_05] = useState(false)

  const toggleFaq = (index: number) => {
    switch (index) {
      case 0:
        setFaqOpen_01(!faqOpen_01)
        break;
      case 1:
        setFaqOpen_02(!faqOpen_02)
        break;

      case 2:
        setFaqOpen_03(!faqOpen_03)
        break;

      case 3:
        setFaqOpen_04(!faqOpen_04)
        break;

      case 4:
        setFaqOpen_05(!faqOpen_05)
        break;
      default:
        break;
    }
  }

  const isFaqOpen = (index: number) => {
    switch (index) {
      case 0:
        return faqOpen_01
      case 1:
        return faqOpen_02

      case 2:
        return faqOpen_03

      case 3:
        return faqOpen_04

      case 4:
        return faqOpen_05
      default:
        return false
    }
  }

  return <Wrap>
    <TitleImg src={isDesktop ? title : titleMobile} alt="title" />
    <Content>
      {
        FAQ_LIST.map((faq, index) => (
          <div key={index}>
            <FaqContainer>
              <SortItem>
                {index + 1}
              </SortItem>
              <FaqTitle onClick={() => toggleFaq(index)}>
                {faq.title}
              </FaqTitle>
            </FaqContainer>
            <Collapse isOpened={isFaqOpen(index)}>
              <FaqContentWrap>
                <FaqContent>
                  {faq.content}
                </FaqContent>
              </FaqContentWrap>
            </Collapse>
          </div>
        ))
      }
    </Content>

  </Wrap>
}

export default FaqSection