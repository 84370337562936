import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import mint from "../images/mint.png"
import mintMobile from "../images/mint-mobile.png"
import aboutus from "../images/title/aboutus.png"
import aboutusMobile from "../images/title/aboutus-mobile.png"
import decoMobile from "../images/deco/deco-img-01-mobile.png"
import wave from "../images/waves/wave02.png"
import waveMobile from "../images/waves/wave02-mobile.png"


const Container = styled.div`
  padding: 110px 0 107px;
  background-color: #2498ED;
  background-image: url(${waveMobile});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  @media (min-width: 768px) {
    padding: 15% 0 10%;
    background-image: url(${wave});
  }
  @media (min-width: 1600px) {
    padding: 12% 0 13%;
  }
`

const Content = styled.div`
  padding: 0 24px;
  text-align: center;
  @media (min-width: 1200px) {
    padding: 0;
    max-width: 1007px; 
    margin: 0 auto;
  }
`

const Title = styled.div`
  font-family: 'Coiny', cursive;
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 35px;
  line-height: 38px;
  margin-bottom: 6px;
  @media (min-width: 768px) {
    font-size: 55px;
    line-height: 60px;
    margin-bottom: 13px;
  }
`

const Subtitle = styled.div`
  font-family: 'Coiny', cursive;
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    font-size: 35px;
    line-height: 38px;
    margin-bottom: 25px;
  }
`

const MintButtonSection = styled.div`
  position: relative;
  margin-bottom: 39px;
  @media (min-width: 768px) {
    margin-bottom: 22px;
  }
`

const MintImg = styled.img`
  width: 176px;
  @media (min-width: 768px) {
    width: 224px;
  }
`

const MintBtn = styled.button`
  position: absolute;
  border: 3px solid transparent;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 8px);
  padding: 7px 40px;
  font-family: 'Coiny';
  font-style: normal;
  font-size: 50px;
  line-height: 69px;
  height: 69px;
  background: linear-gradient(to bottom, #FF7777, #F9BCA0) padding-box, linear-gradient(to bottom, #FFC2C2, #FC62FF) border-box;
  border-radius: 40px;
  span {
    background: linear-gradient(180deg, #FDC331 0%, #FFFFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; 
  }
  :hover {
    cursor: pointer;
    background: linear-gradient(180deg, #FF7777 -7.42%, #FBDC8F 31.74%, #BFEE92 69.23%, #6CB2E6 100%) padding-box, linear-gradient(135deg, #8ECBF8, #FBDC8F) border-box;
    span {
      background: linear-gradient(180deg, #FBDC8F 0%, #FF7777 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent; 
    }
  }
  &:disabled {
    cursor: not-allowed;
    background: linear-gradient(to bottom, #838383, #bdbdbd) padding-box, linear-gradient(to bottom, #c9c9c9, #fff) border-box;
    span {
      background: linear-gradient(180deg, #C9C9C9 0%, #FFFFFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent; 
    }
  }
  @media (min-width: 768px) {
    padding: 9px 65px;
    font-weight: 400;
    font-size: 60px;
    line-height: 66px;
    height: 72px;
    border-radius: 70px;
  }
`

const AboutusSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 52px;
  }
`

const AboutusImg = styled.img`
  width: 113px;
  @media (min-width: 768px) {
    width: 201px;
  }
`

const AboutusText = styled.div`
  font-family: 'Average Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 10px 37px;
  color: #fff;
  @media (min-width: 768px) {
    padding: 0;
    font-size: 20px;
    line-height: 35px;
    text-align: left;
  }
`

const DecoImg = styled.img`
  width: 99px;
  position: absolute;
  left: 4px;
  top: 8px;
`

interface MintSectionProps {
  mintButtonClick: () => void
}


const MintSection = (props: MintSectionProps) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  return <Container>
    <Content>
      <Title>Ready?</Title>
      <Subtitle>For Your Adventure?</Subtitle>
      <MintButtonSection>
        <MintImg src={isDesktop ? mint : mintMobile} alt="mint" loading="lazy" />
        <MintBtn disabled={true} onClick={props.mintButtonClick}>
          <span>MINT</span>
        </MintBtn>
      </MintButtonSection>
      <AboutusSection>
        {!isDesktop && <DecoImg src={decoMobile} />}
        <AboutusImg src={isDesktop ? aboutus : aboutusMobile} alt="aboutus" />
        <AboutusText>
          Deed of Genesis is a collectible NFT project of RPG strategy game centered around manga and community. Like the manga and gaming enthusiasts, we’re also eager to create artistic brand value and community cohesion. Also, our NFT lab- Myth Lab, will be expanding to other NFT projects and enter the Metaverse soon.
        </AboutusText>
      </AboutusSection>
    </Content>
  </Container>
}

export default MintSection