import styled from 'styled-components'
import twitter from "../images/logos/twitter-LOGO.png"
import { useMediaQuery } from 'react-responsive'

const Card = styled.div`
  background: linear-gradient(to bottom, #fff, #FFC2C2) padding-box,
  linear-gradient(to bottom, #FC62FF, #FFE7E7) border-box;
  border: 3px solid transparent;
  border-radius: 24px;
  padding: 16px 22px;
  display: flex;
  position: relative;
  @media (min-width: 768px) {
    border: 4px solid transparent;
    border-radius: 30px;
    padding: 30px 10px;
    flex-direction: column;
    height: 100%;
  }
`

const Avatar = styled.img`
  height: 85px;
  @media (min-width: 768px) {
    height: 111px;
  }
`

const Title = styled.div`
  color: #B64E4E;
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 80px;
  @media (min-width: 768px) {
    font-size: 15px;
    width: 100%;
  }
`

// for mobile
const CardContent = styled.div`
  margin-left: 16px;
`

const NameText = styled.div`
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #FF7777;
  margin-bottom: 13px;
  text-align: left;
  width: 100%;
  @media (min-width: 768px) {
    text-align: center;
    font-size: 25px;
    line-height: 16px;
    margin-bottom: 21px;
    word-break: break-word;
  }
`

const Desc = styled.ul`
  font-family: 'Anek Latin';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  padding-left: 20px;
  text-align: left;
  @media (min-width: 768px) {
    font-size: 12px;
    line-height: 15px;
  }
`

const SocialLink = styled.a`
  position: absolute;
  display: inline-block;
  top: 12px;
  right: 12px;
  img {
    width: 27px;
  }
  @media (min-width: 768px) {
    top: auto;
    right: 12px;
    bottom: 12px;
    img {
      width: 31px;
    }
  }
`

// for desktop
const CardBody = styled.div`
  display: flex;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
  
`

interface CardProps {
  name: string
  title: string
  img: string
  description: string[]
  twitterUrl?: string
}

const MemberCard = (props: CardProps) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  return isDesktop ?
    <Card>
      <NameText>{props.name}</NameText>
      <CardBody>
        <div>
          <Avatar src={props.img} alt="member" />
          <Title>{props.title}</Title>
        </div>
        <Desc>
          {
            props.description
              .map((desc, index) => <li key={index}>{desc}</li>)
          }
        </Desc>
      </CardBody>
      {props.twitterUrl && <SocialLink href={props.twitterUrl} target="_blank"><img src={twitter} alt="twitter" /></SocialLink>}
    </Card>
    :
    <Card>
      <div>
        <Avatar src={props.img} alt="member" />
        <Title>{props.title}</Title>
      </div>
      <CardContent>
        <NameText>{props.name}</NameText>
        <Desc>
          {
            props.description
              .map((desc, index) => <li key={index}>{desc}</li>)
          }
        </Desc>
      </CardContent>
      {props.twitterUrl && <SocialLink href={props.twitterUrl} target="_blank"><img src={twitter} alt="twitter" /></SocialLink>}
    </Card>
}


export default MemberCard