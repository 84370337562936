/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { Test2, Test2Interface } from "../Test2";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "newValue",
        type: "uint256",
      },
    ],
    name: "SetValue",
    type: "event",
  },
  {
    inputs: [],
    name: "add",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getValue",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newValue",
        type: "uint256",
      },
    ],
    name: "setValue",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "value",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b50610153806100206000396000f3fe608060405234801561001057600080fd5b506004361061004c5760003560e01c806320965255146100515780633fa4f245146100675780634f2be91f14610070578063552410771461007a575b600080fd5b6000545b60405190815260200160405180910390f35b61005560005481565b61007861008d565b005b6100786100883660046100e1565b6100a6565b600560008082825461009f91906100f9565b9091555050565b60008190556040518181527fed8b07065ef0737c0cfb1bf1e23ccc881d797ec9804f74230a360b84982938ab9060200160405180910390a150565b6000602082840312156100f2578081fd5b5035919050565b6000821982111561011857634e487b7160e01b81526011600452602481fd5b50019056fea2646970667358221220f387a90f09da2fbec3f4fe04b152827b66c1f16c801336c8a60a484ddc200b8764736f6c63430008040033";

export class Test2__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<Test2> {
    return super.deploy(overrides || {}) as Promise<Test2>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): Test2 {
    return super.attach(address) as Test2;
  }
  connect(signer: Signer): Test2__factory {
    return super.connect(signer) as Test2__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): Test2Interface {
    return new utils.Interface(_abi) as Test2Interface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Test2 {
    return new Contract(address, _abi, signerOrProvider) as Test2;
  }
}
