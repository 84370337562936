import { SupportedChainId } from '../connectors/index'
type AddressMap = { [chainId: number]: string }
export const TEMPLATE_NFT_ADDRESS: AddressMap = {
    [SupportedChainId.MAINNET]: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
    [SupportedChainId.RINKEBY]: '0x47EC596C581D9354dbCb0E2C00aBe298b51EB32A',
    [SupportedChainId.HARDHAT]: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
}
export const DEED_OF_GENESIS_ADDRESS: AddressMap = {
    [SupportedChainId.MAINNET]: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
    [SupportedChainId.RINKEBY]: '0x5d99a2796cecc2DbaEA6eFf8d25376eE6EFEcB79',
    [SupportedChainId.HARDHAT]: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
}
export const DISCORD_LINK = "https://discord.gg/yBD99RSj9e";
export const TWITTER_LINK = "https://twitter.com/DeedofGenesis";
export const OPENSEA_LINK = "";
export const MAX_SUPPLY = 999;
export const START_TIME = new Date(2022, 2-1, 28, 22, 30).valueOf();
export const MINT_PRICE = 0.07;