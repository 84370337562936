import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import story from "../images/title/story.png"
import storyMobile from "../images/title/story-mobile.png"
import deco02 from "../images/deco/deco-img-02.png"
import decoMobile02 from "../images/deco/deco-img-02-mobile.png"
import deco03 from "../images/deco/deco-img-03.png"
import decoMobile03 from "../images/deco/deco-img-03-mobile.png"
import deco04 from "../images/deco/deco-img-04.png"
import decoMobile04 from "../images/deco/deco-img-04-mobile.png"
import wave from "../images/waves/wave03.png"
import waveMobile from "../images/waves/wave03-mobile.png"


const Wrap = styled.div`
  position: relative;
  background-image: url(${waveMobile}), linear-gradient(to bottom, #2498ED 0%, #3FCB51 33.85%);
  background-repeat: no-repeat, no-repeat;
  background-position: bottom center, top left;
  background-size: 100%, auto;
  padding: 40px 0 100px;
  text-align: center;
  margin-top: -1px;
  @media (min-width: 768px) {
    background-image: url(${wave}), linear-gradient(to bottom,#2498ED 0%,#3FCB51 33.85%,#3FCB51 90%, #9EC37A 100%);
    padding: 24px 0 197px;
  }
  @media (min-width: 1600px) {
    padding: 4% 0 15%;
  }
`

const TitleSection = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 27px;
  @media (min-width: 768px) {
    margin-bottom: 50px;
  }
`

const TitleImg = styled.img`
  width: 166px;
  @media (min-width: 768px) {
    width: 323px;
  }
`

const TitleDeco = styled.img`
  position: absolute;
  width: 87px;
  top: 10px;
  right: -46px;
  @media (min-width: 768px) {
    right: -20%;
    width: 121px;
    top: 42px;
  }
  @media (min-width: 1200px) {
    right: -54px;
    top: 44px;
  }
`

const Content = styled.div`
  padding: 6px 61px;
  @media (min-width: 1200px) {
    max-width: 884px;
    padding: 0;
    margin: 0 auto;
  }
`

const Subtitle = styled.div`
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 33px;
  text-align: center;
  position: relative;
  color: rgba(255,255,255, 0.5);
  @media (min-width: 768px) {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 20px;
  }
`

interface ParagraphProps {
  marginBottom?: string
}
const Paragraph = styled.p<ParagraphProps>`
  font-family: 'Average Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin-bottom: ${props => props.marginBottom || '0'};
  position: relative;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const TitleDeco03 = styled.img`
  width: 168px;
  position: absolute;
  left: -23px;
  top: 335px;
  @media (min-width: 768px) {
    width: 408px;
    left: -45px;
    top: 33%;
  }
  @media (min-width: 1600px) {
    top: 36%;
  }
`


const TitleDeco04 = styled.img`
  width: 97px;
  position: absolute;
  right: 0;
  top: -44px;
  transform: translateX(50%);
  @media (min-width: 1440px) {
    top: 10px;
    width: 160px;
    transform: translateX(80%);
  }
`

const StorySection = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  return <Wrap>
    <TitleDeco03 src={isDesktop ? deco03 : decoMobile03} alt="deco" />
    <TitleSection>
      <TitleImg src={isDesktop ? story : storyMobile} alt="title" loading="lazy" />
      <TitleDeco src={isDesktop ? deco02 : decoMobile02} alt="deco" loading="lazy" />
    </TitleSection>
    <Content>
      <Subtitle>DEED</Subtitle>
      <Paragraph marginBottom={isDesktop ? '78px' : '12px'}>
        The “Bright Universe” was created by God, which was the part of the universe we have all noticed. And the “Dark Universe” was formed in the black hole and created by Asura, which we do not know about it. The two-part of the universe restrain each other but also rely on each other.
      </Paragraph>
      <Subtitle>
        OF
      </Subtitle>
      <Paragraph marginBottom={isDesktop ? '51px' : '28px'}>
        After a great number of years, God created mankind in his image, and built up the “Human World”. Since then, the silence and the balance of the universe were finally broken, causing each kind of spirit in the universe to be alarmed. At the same time, the doomsday timer began to count down…
      </Paragraph>
      <Subtitle>
        Genesis
        <TitleDeco04 src={isDesktop ? deco04 : decoMobile04} alt="deco" />
      </Subtitle>
      <Paragraph>
        Unlike other spirits in the universe, humans are born without divine power but have infinite wisdom. However, humans are not satisfied with anything they possess but are expanding their desires to get anything they could. Eventually, they have broken all kinds of taboos and enchantments, and the Universe has officially entered the era of chaos...
      </Paragraph>
    </Content>
  </Wrap>
}

export default StorySection