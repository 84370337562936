import { Button } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { injected } from '../connectors';
import { getEllipsisTxt } from '../helpers/formatters';
import { useActiveWeb3React } from '../hooks/web3';


interface Props {

}
const styles = {
    button: {
        fontWeight: "700",
        background: '#fff',
        color: '#FF7043',
        border: '1px solid #FF7043',
        height: 'auto',
        fontFamily: 'Inter, sans-serif'
    },
}


const Account = (props: Props) => {
    const { activate, account } = useActiveWeb3React()
    const isDesktop = useMediaQuery({
        query: '(min-width: 768px)'
    })
    if (account) {
        return (
            <div>
                <Button
                    style={{
                        ...styles.button,
                        padding: isDesktop ? '6px 20px' : '3px 12px',
                        borderRadius: isDesktop ? '50px' : '20px',
                        fontSize: isDesktop ? '15px' : '13px',
                        lineHeight: isDesktop ? '18px' : '16px',
                    }}>
                    {getEllipsisTxt(account)}
                </Button>
            </div>
        )
    }
    return (
        <div>
            <Button style={{
                ...styles.button,
                padding: isDesktop ? '6px 20px' : '3px 12px',
                borderRadius: isDesktop ? '50px' : '20px',
                fontSize: isDesktop ? '15px' : '13px',
                lineHeight: isDesktop ? '18px' : '16px',
            }}
                onClick={() => {
                    activate(injected, undefined, true)
                        .catch(err => { console.log(err) })
                }}
            >
                CONNECT WALLET
            </Button>


        </div >
    )
}

export default Account
