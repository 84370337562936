import styled from "styled-components"
import { TWITTER_LINK } from '../constants';
import twitter from "../images/logos/twitter-LOGO.png"

const Footer = styled.footer`
  background-color: #fff; 
`

const Copyright = styled.footer`
  padding: 39px 0 33px;
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: #FF7043;
  @media (min-width: 768px) { 
    padding: 69px 0 45px;
    font-size: 20px;
    line-height: 22px;
  }
`

const SocialSection = styled.div`
  text-align: center;
  padding: 11px 0 27px;
  @media (min-width: 768px) { 
    padding: 20px 0 43px;
  }
`

const SocialText = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 23px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #9A9A9A;
  @media (min-width: 768px) { 
    justify-content: center;
    padding-right: 0;
    padding: 14px 0 26;
  }
`

const TwitterIcon = styled.img`
  width: 27px;
  @media (min-width: 768px) { 
    width: 33px;
  }
`

const Link = styled.a`
  display: inline-block;
  margin-left: 18px;
`

const Divider = styled.div`
  width: calc(100% - 42px);
  height: 2px;
  background-color: #BDBDBD;
  margin-left: auto;
  @media (min-width: 768px) { 
    width: calc(100% - 160px);
    margin: 0 auto;
  }
`


const AppFooter = () => {
  return <Footer>
    <Copyright>
      © Copyright 2022.Deed of Genesis<br />All rights reserved
    </Copyright>
    <Divider />
    <SocialSection>
      <SocialText>
        Join The Community
        <Link href={TWITTER_LINK} target="_blank">
          <TwitterIcon src={twitter} alt="twitter" />
        </Link>
      </SocialText>
    </SocialSection>
  </Footer>
}

export default AppFooter